import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ width, height }) => {
	return (
		<svg
			width={height !== 854 ? height * (2155 / 854) : width}
			height={width !== 2155 ? width * (854 / 2155) : height}
			viewBox='0 0 68 68'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='Group_1' data-name='Group 1' transform='translate(-607 -7437)'>
				<circle
					id='Ellipse_239'
					data-name='Ellipse 239'
					cx='34'
					cy='34'
					r='34'
					transform='translate(607 7437)'
					fill='#fff'
				/>
				<g id='Group_699' data-name='Group 699' transform='translate(626 7450)'>
					<ellipse
						id='Ellipse_2'
						data-name='Ellipse 2'
						cx='3.639'
						cy='3.639'
						rx='3.639'
						ry='3.639'
						transform='translate(3.079 9.797)'
						fill='#ffbe00'
					/>
					<circle
						id='Ellipse_1'
						data-name='Ellipse 1'
						cx='5.108'
						cy='5.108'
						r='5.108'
						transform='translate(14.345 0)'
						fill='#ffbe00'
					/>
					<path
						id='Path_1'
						data-name='Path 1'
						d='M3009.335,87.684h0c-.129-.128-.263-.25-.4-.367a10.133,10.133,0,0,0-17.423,7.051V112.18c1.937,1.49,3.359,2.41,3.359,2.41s11.882-7.679,15.783-15.452l.008-.016a10.027,10.027,0,0,0,.529-1.185l.018-.047a10.24,10.24,0,0,0-1.872-10.205Z'
						transform='translate(-2980.874 -72.604)'
						fill='#8941ff'
					/>
					<path
						id='Path_2'
						data-name='Path 2'
						d='M2845.754,179.693a7.005,7.005,0,0,0-7,7c0,.039,0,.077,0,.116s0,.106,0,.161c0,5.044,6.5,10.849,10.637,14.037,1.937,1.491,3.359,2.41,3.359,2.41V186.691A7,7,0,0,0,2845.754,179.693Z'
						transform='translate(-2838.755 -161.429)'
						fill='#00e0f1'
						opacity='0.9'
					/>
				</g>
			</g>
		</svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
